import { createTheme } from "@material-ui/core/styles"
import "./font.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      accent: "linear-gradient(92deg, #BC000B 0%, #BD6600 100%)", // Акцентный цвет
    },
    secondary: {
      main: "#fff", // Цвет для текста в элементах и у заголовков
      title: "linear-gradient(180deg, #FFFFFF 33.33%, #7D7D7D 100%)", // Цвет для главных заголовков и заголовков в одну линию
      subtitle: "#5A5A5A", //  Цвет для подзаголовков
      subtext: "#A6A6A6", // Цвет обычного текста (p)
    },
    background: {
      main: "#000", // Цвет основного фона
      productCard:
        "linear-gradient(180deg, #1E1E1E 50%, rgba(0, 0, 0, 0) 100%)", // Градиент карточки товара
      card: "#1E1E1E", // Цвет карточек
      highlight: "#2B2B2B", // Цвет для подсвечивания внутри карточек
      button: "#fff", // Цвет фона кнопки
      productCardButton: "#353535", // Цвет фона кнопок на карточке товара
    },
    width: {
      limit: "1600px",
    },
  },
  typography: {
    fontFamily: ["Raleway"].join(","),
    h1: {
      // Названия товаров, статей, акций, имя спортсмена на их индивидуальных страницах + Основные заголовки страниц
      color: "#fff",
      fontSize: "72px",
      fontWeight: "700",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "3.75vw",
      },
      "@media (max-width:1024px)": {
        fontSize: "3.75vw",
      },
      "@media (max-width:767px)": {
        fontSize: "8vw",
      },
    },
    h2: {
      // Заголовки структурных блоков + Заголовки названий серий (для главной страницы) + Название товара, акции, новости в карточке
      background: "linear-gradient(180deg, #FFFFFF 33.33%, #7D7D7D 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontWeight: "700",
      fontSize: "64px",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "3.33vw",
      },
      "@media (max-width:1024px)": {
        fontSize: "3.33vw",
      },
      "@media (max-width:767px)": {
        fontSize: "6vw",
      },
    },
    h3: {
      // Заголовки лендингов + Заголовки трех преимуществ на странице серии
      color: "#fff",
      fontWeight: "700",
      fontSize: "64px",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
    },
    h4: {
      // Пункты меню в шапке и футере + все uppercase надписи
      color: "#fff",
      fontWeight: "600",
      fontSize: "24px",
      textTransform: "uppercase",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "1.25vw",
      },
      "@media (max-width:1024px)": {
        fontSize: "2.2vw",
      },
      "@media (max-width:767px)": {
        fontSize: "5.80vw",
      },
    },
    h5: {
      // Заголовки вопросов на странице FAQ + Заголовки структурных частей новостей, акций
      color: "#fff",
      fontWeight: "500",
      fontSize: "40px",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "2.08vw",
      },
      "@media (max-width:1024px)": {
        fontSize: "2.08vw",
      },
      "@media (max-width:767px)": {
        fontSize: "4.5vw",
      },
    },
    h6: {
      // Заголовки на странице "О BSE – История бренда"
      color: "#fff",
      fontWeight: "600",
      fontSize: "40px",
      textTransform: "uppercase",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "2.5vw",
      },
      "@media (max-width:1024px)": {
        fontSize: "2vw",
      },
      "@media (max-width:767px)": {
        fontSize: "6vw",
      },
    },
    body1: {
      // Обычный текст (например, текст на новостной странице)
      color: "#A6A6A6",
      fontWeight: "400",
      fontSize: "26px",
      lineHeight: "150%",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "1.35vw",
      },
      "@media (max-width:767px)": {
        fontSize: "18px",
      },
    },
    body2: {
      // Обычный текст, но белый
      color: "#fff",
      fontWeight: "400",
      fontSize: "18px",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "0.94vw",
      },
      "@media (max-width:1024px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width:767px)": {
        fontSize: "3vw",
      },
    },
    subtitle1: {
      // Подзаголовки в лендингах
      color: "#5A5A5A",
      fontWeight: "500",
      fontSize: "48px",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      "@media (max-width:1919px)": {
        fontSize: "2.50vw",
      },
      "@media (max-width:767px)": {
        fontSize: "5vw",
      },
    },
    subtitle2: {
      // Показатели на карточке товара
      color: "#fff",
      fontWeight: "400",
      fontSize: "24px",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
    },
    subtitle3: {
      // Подписи к фотографиям
      color: "#A6A6A6",
      fontWeight: "500",
      fontSize: "14px",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
    },
  },
  overrides: {
    // Название класса компонента
    MuiButton: {
      // Название css-правила, смотреть в mui
      text: {
        color: "#000",
        fontWeight: "500",
        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
  },
  props: {
    // Название класса компонента
    MuiButton: {
      // Props, который нужно изменить
      disableRipple: true, // Отменяет эффект волны для всех кнопок
    },
  },
})

export default theme
