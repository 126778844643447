import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./src/templates/theme.js"
// import ClientOnly from "./src/clientOnly.js"

export default function MuiRootWrapper({ element }) {
  return (
    // <ClientOnly>
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
    // </ClientOnly>
  )
}
